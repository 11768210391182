<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Sharing Options</h3>

    <Share
        v-model="form.data.settings.share"
    />

    <template v-if="form.data.settings.share && form.data.settings.share!=='Do not share'" >
      <div class="question-box with-arrow">
        <People
            v-model="form.data.settings.people"
            :settings="form.data.settings"
            :optionsData="primaryOptions"
        />
      </div>
    </template>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import {vaultPartnerHelpers} from "@/components/common/questionnaires/fieldsets/vault/helpers/vaultPartnerHelpers";
import {vaultPeopleHelpers} from "@/components/common/questionnaires/fieldsets/vault/helpers/vaultPeopleHelpers";


import Share from "../../question/questions/vault/vault_item/Share";
import People from "../../question/questions/vault/vault_item/People";

export default {
  name: 'VaultItemShareOptions',
  mixins: [
    fieldsetHelpers,
    vaultPartnerHelpers,
    vaultPeopleHelpers,
  ],
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  components: {
    Share,
    People,
  },
  mounted() {
    // refresh people on mount
    this.$store.dispatch('peopleFetch')
  },
  data() {
    return {
      label: 'Sharing Options', // used for steps progress
      primaryOptions: [],
      db: {
        saveLocation: `vault_item_data_save?vault_item_id=${this.$route.params.vaultItemId}`,
        saveField: 'settings',
        formPath: 'data.settings',
      },
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    settings() {
      // required for attorneys helpers file
      return this.form.data.settings
    },
    data() {
      return this.form.data.settings
    }
  }
};
</script>
