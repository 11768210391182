<template>
  <QuestionBase
      :question="question"
      :errors="[...serverErrors, ...jsErrors]"
      :valid="!!data">
    <InputRadio
        v-model="data"
        :options="typeOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {vaultOptions} from "../../../options/vaultOptions";
import { fieldsetLoader } from "@/mixins/fieldsetLoader";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'Type',
  mixins: [vaultOptions, questionLoadHelpers, fieldsetLoader],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    objectId: {
      type: [Number,String],
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What would you like to add to your Vault?',
        subTitle: 'It is possible to add any files, videos and passwords. If you would like to add general information please save it in a word document or PDF file.',
        tip: ''
      },
      db: {
        saveLocation: 'vault_item_save',
        saveField: 'type',
        objectId:this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
}
</script>
