<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputRadio
        ref="input"
        v-model="data"
        :options="shareOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {vaultOptions} from "../../../options/vaultOptions";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'Share',
  mixins: [questionLoadHelpers, vaultOptions],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
  },
  mounted () {
    this.setOptions()
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'When would you want the item to be shared?',
        subTitle: 'If we are notified of you passing or incapacity we can make the information available to the people specified. Where we have prepared your Wil, there is a reference to your Vault so your family will know to contact us. In relation to incapacity we would only be contacted if you provide our details to your attorneys or family.',
        tip: null,
      },
      db: {
        saveLocation: `vault_item_data_save?vault_item_id=${this.$route.params.vaultItemId}`,
        saveField: 'share',
        formPath: 'data.settings.share',
        jsonSaveField: 'share',
      },
      args: {
        skippable: false,
        label: null,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      options: []
    }
  },
  methods: {
    setOptions () {
      this.options = [
        {
            label: 'Do not share',
            value: 'Do not share'
        },
        {
            label: 'On Death',
            value: 'On Death'
        },
        {
            label: 'On Incapacitaded',
            value: 'On Incapacitaded'
        },
        {
            label: 'On Death or Incapacitaded',
            value: 'On Death or Incapacitaded'
        },
      ]
    }
  }
}
</script>
