<template>
  <vue-dropzone
      id="dropzone"
      ref="dropzone"
      :awss3="awsS3"
      :options="dropzoneOptions"
      :disabled="disabled"
      class="d-flex flex-row"
      @vdropzone-error="handleError"
      @vdropzone-success="createDocument"
      @vdropzone-s3-upload-error="s3UploadError"
      @vdropzone-s3-upload-success="s3UploadSuccess"
  ></vue-dropzone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
// import {http, httpQuestionnaire} from "@/services";
import {httpQuestionnaire} from "@/services";
import {toast} from "@/mixins/toast";

export default {
  name: "VaultItemDropZone",
  mixins: [toast],
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    vault_item: {
      type: Object,
      required: true,
    },
    dropMessage: {
      type: String,
      default: "Drag files here, or click"
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    awsS3() {
      let params = {
        vault_item: true,
      }
      return {
        signingURL: `${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
        headers: {
          Authorization: this.$store.getters.accessCode ? `Code ${this.$store.getters.accessCode}` : `JWT ${this.$store.getters.tokens.access}`
        },
        params: params,
        sendFileToServer: false,
        withCredentials: true,
        disablePreviews: true,
        acceptedFiles: true
      };
    },
  },
  data() {
    return {
      documentType: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/api/upload_pre_sign`,
        timeout: 360000,
        maxFiles: 1,
        maxFilesize: 1024, // max file 1GB!
        headers: {},
        addRemoveLinks: true,
        dictDefaultMessage: this.dropMessage,
        autoProcessQueue: true,
      },
    };
  },
  methods: {
    uploadManual() {
      this.$refs.documentDropzone.$refs.dropzoneElement.click();
    },
    addedFile(file) {
      console.log("added file", file);
    },
    s3UploadError(errorMessage) {
      console.log("s3 upload error", errorMessage);
    },
    s3UploadSuccess(s3ObjectLocation) {
      console.log("s3 upload success", s3ObjectLocation);
    },
    handleError(error, message, other) {
      console.log(error, message, other)
    },
    createDocument(file) {

      let url = 'vault_item_upload'

      let data = {
        vault_item: this.vault_item.id,
        url: file.s3Signature.key,
        mime_type: file.type,
      }

      httpQuestionnaire.post(url, data).then((response) => this.createDocumentDone(file, response.data, !!this.request)).catch((error) => {
        this.toast(error.response.data, 'Error', 'danger');
      });

      // if (this.$store.getters.accessCode) {
      //   httpQuestionnaire.post(url, data).then((response) => this.createDocumentDone(file, response.data, !!this.request)).catch((error) => {
      //     this.toast(error.response.data, 'Error', 'danger');
      //   });
      // } else {
      //   http.post(url, data).then((response) => this.createDocumentDone(file, response.data, !!this.request)).catch((error) => {
      //     this.toast(error.response.data, 'Error', 'danger');
      //   });
      // }

    },
    createDocumentDone(file, data, removeFile=true) {
      this.toast("File uploaded");
      this.$emit("refresh");
      this.$emit('saved', data)
      // remove file from queued files
      if (removeFile) this.$refs.dropzone.removeFile(file);
      // if no files left for uploading then emit done (close modal)
      let docs = this.$refs.dropzone.getUploadingFiles();
      if (!docs.length) this.$emit("done");
    },
    allDone(success) {
      console.log("all done", success);
    },
  },
};
</script>
