var render = function render(){var _vm=this,_c=_vm._self._c;return _c('QuestionBase',{attrs:{"id":_vm.db.saveField,"errors":[..._vm.jsErrors],"question":_vm.question,"valid":!!_vm.data.length}},[_c('div',{staticClass:"accordion sub-items"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.data),function(person,index){return _c('ObjectCard',{key:'sharedPeopleData' + person.id,ref:'sharedPeopleData' + index,refInFor:true,attrs:{"id":'sharedPeopleData' + index,"select-mode":true,"index":index,"selected":true,"loading":false,"title":_vm.cardTitle(person),"value":_vm.data[index],"show-delete":false,"type":"sharedPeopleData"},on:{"click":function($event){return _vm.deselectConfirm(
                person,
                'Remove person from your list?'
              )},"delete":function($event){return _vm.deletePerson(person.id)},"save":function($event){return _vm.savePerson(
            null,
            'sharedPeopleData' + index
          )}}},[(person.type === 'person')?_c('PersonVault',{key:'sharedPeople-form-data' + person.id,attrs:{"errors-post":_vm.errorsPatch,"no-save":true,"objectId":person.id},on:{"save":function($event){return _vm.savePersonForm($event, person)}},model:{value:(_vm.data[index]),callback:function ($$v) {_vm.$set(_vm.data, index, $$v)},expression:"data[index]"}}):_vm._e()],1)}),1)],1),_c('transition',{attrs:{"name":"fade"}},[[_c('b-button',{staticClass:"btn-question w-100",on:{"click":function($event){_vm.show.addPerson = true}}},[_c('i',{staticClass:"i-Add text-25 font-weight-800 mr-2"}),_vm._v(" Add Person ")])]],2),_c('VaultPeopleModal',{attrs:{"options":_vm.optionsData,"dataSelected":_vm.data,"title":"Add Person","sub-title":'Select existing people or add new people. You can select multiple individuals.',"hide-type-options":true},on:{"dataSelectedUpdate":function($event){_vm.data = $event},"save":_vm.save},model:{value:(_vm.show.addPerson),callback:function ($$v) {_vm.$set(_vm.show, "addPerson", $$v)},expression:"show.addPerson"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }