export const vaultOptions = {
    data() {
        return {
            shareOptions: [
                {
                    label: 'Do Not Share',
                    value: 'Do not share'
                },
                {
                    label: 'On Death',
                    value: 'On Death'
                },
                {
                    label: 'On Incapacitation',
                    value: 'On Incapacitation'
                },
                {
                    label: 'On Death or Incapacitation',
                    value: 'On Death or Incapacitation'
                },
            ],
            typeOptions: [
                {
                    label: 'Video',
                    value: 'Video'
                },
                {
                    label: 'Password',
                    value: 'Password'
                },
                {
                    label: 'File',
                    value: 'File'
                },
            ],
        }
    }
}