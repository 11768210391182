<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Item Details</h3>

    <Type
        v-model="form.type"
        :objectId="vault_item_id"
    />

    <template v-if="form.type && form.type!=='Password'">
      <vault-item-drop-zone ref="vaultItemDropzone"
                          :vault_item="form"
                          :drop-message="'Drag a file here, or click to select'"
                          style="min-height: 132px; min-width: 200px"
                          class="mb-12"
                          @refresh="$emit('refresh')"
                          @saved="$emit('saved', $event)"
                          />

      <FilePassword
          v-model="form.password"
          :objectId="vault_item_id"
      />

    </template>

    <Title
        v-model="form.title"
        :objectId="vault_item_id"
  />

    <template v-if="form.type==='Password'">
      <WebAddress
          v-model="form.web_address"
          :objectId="vault_item_id"
      />

      <UserNamePassword :valueUserName="form.user_name"
                :valuePassword="form.password"
                :objectId="vault_item_id"
                @inputUserName="form.user_name=$event"
                @inputPassword="form.password=$event"
                />
    </template>



    <Comment v-model="form.comment"
        :objectId="vault_item_id"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import VaultItemDropZone from "../../../documents/requests/VaultItemDropZone";

import Type from "../../question/questions/vault/vault_item/Type";
import Title from "../../question/questions/vault/vault_item/Title";
import WebAddress from "../../question/questions/vault/vault_item/WebAddress";
import UserNamePassword from "../../question/questions/vault/vault_item/UserNamePassword";
import FilePassword from "../../question/questions/vault/vault_item/FilePassword";
import Comment from "../../question/questions/vault/vault_item/Comment";

export default {
  name: 'VaultItem',
  mixins: [
    fieldsetHelpers,
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    VaultItemDropZone,
    Type,
    Title,
    WebAddress,
    UserNamePassword,
    FilePassword,
    Comment,
  },
  data() {
    return {
      label: 'Item Details', // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    vault_item_id(){
      if(this.$route.params.vaultItemId){
        return this.$route.params.vaultItemId
      }
      return null
    }
  }
};
</script>
