<template>
  <QuestionBase
      :question="question"
      :valid="!!(valueUserName && valuePassword)"
      :errors="errors"
  >
    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-50">
        <InputBasic
            :value="valueUserName"
            @input="$emit('inputUserName', $event)"
            ref="userName"
            :args="userNameInputOptions"
            :db="db.userName"
            :skipped="skipped"
            :no-save="noSave"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.userName=$event"
            @jsErrors="jsErrors=$event"
        />

      </div>
      <div class="question question-multipart field-50">
        <InputBasic
            :value="valuePassword"
            @input="$emit('inputPassword', $event)"
            ref="password"
            :args="passwordInputOptions"
            :db="db.password"
            :skipped="skipped"
            :no-save="noSave"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.password=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
    </div>
  </QuestionBase>
</template>

<script>
import {skipHelpers} from "../../helpers/skipHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputBasic from "../../../inputs/InputBasic";

export default {
  name: 'UserNamePassword',
  components: {
    InputBasic,
    QuestionBase
  },
  mixins: [skipHelpers, questionLoadHelpers],
  props: {
    valueUserName: {
      type: String,
      required: false
    },
    valuePassword: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: [Number,String],
      required: false
    },
  },
  computed: {
    errors() {
      return [...this.serverErrors.password, ...this.serverErrors.userName, ...this.jsErrors]
    }
  },
  data() {
    return {
      question: {
        title: 'What are your credentials?',
        subTitle: 'You can update these details if they change in the future by returning to this page.',
        tip: null
      },
      userNameInputOptions: {
        placeholder: 'e.g. john.doe@mail.com',
        label: 'User Name:',
        type: 'text',
        skippable: false,
        required: false,
      },
      passwordInputOptions: {
        placeholder: 'e.g. Password123',
        label: 'Password:',
        type: 'password',
        skippable: false,
        required: true,
      },
      db: {
        saveLocation: 'vault_item_save',
        saveField: 'user_name',
        userName: {
          saveLocation: 'vault_item_save',
          saveField: 'user_name',
          objectId:this.objectId
        },
        password: {
          saveLocation: 'vault_item_save',
          saveField: 'password',
          objectId:this.objectId
        },
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: {
        userName: [],
        password: [],
      },
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>