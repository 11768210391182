import {vaultPartnerHelpers} from "./vaultPartnerHelpers";
import {vaultRelationshipHelpers} from "./vaultRelationshipHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

export const vaultPeopleHelpers = {
    // required a details computed property: only to be used in root level Executors file
    mixins: [vaultPartnerHelpers, vaultRelationshipHelpers, saveHelpers],
    mounted() {
        // calculate people options
        this.setOptionsData()
        let attributeAdded = false
        // for old created lpa insert new fields into data
        if (!('people' in this.settings)) {
            // set new fields for old created lpas
            this.$set(this.settings, 'people', [])
            attributeAdded = true
        }
        if (!('update_existing_items' in this.settings)) {
            // set new fields for old vault
            this.$set(this.settings, 'update_existing_items', false)
            attributeAdded = true
        }
        if (!('share' in this.settings)) {
            // set new fields for old created lpas
            this.$set(this.settings, 'share', 'Do not share')
            attributeAdded = true
        }
        if (attributeAdded) this.save()
    },
    watch: {
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        settings: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        'settings.share': {
            immediate: false,
            deep: false,
            handler (value) {
                if (value === 'Do not share') {
                    this.clearPeople()
                }
            }
        }
    },
    computed: {
        peopleRequired() {
            return this.settings.share && this.settings.share !== 'Do not share'
        },
        storePeople() {
            return this.$store.getters.people
        }
    },
    methods: {
        async syncOptions() {
            this.clearAllPeople()
            // await this.addPartner()
            this.save()
        },
        // async addPartner() {
        //     // add partner to primary attorneys
        //     if (this.partner) {
        //         // find partner in primary attorneys
        //         let partnerPrimaryIndex = this.settings.people.findIndex(client => client.id === this.partner.id)
        //         if (partnerPrimaryIndex >= 0 && !this.settings.primaryPartner) {
        //             // remove partner
        //             this.settings.people.splice(partnerPrimaryIndex, 1)
        //             return true
        //         } else if (this.settings.primaryPartner) {
        //             // add partner
        //             this.settings.people.push(this.convertPerson(this.partner))
        //             return true
        //         }
        //     } else {
        //         return false
        //     }
        // },
        clearAllPeople() {
            this.settings.people = []
        },
        clearPeople() {
            this.settings.people = []
            this.save()
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfExecutors in final form for will
                // if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })


            // filter out primary or backup options
            if(this.settings && this.settings.people){
                let primaryIds = this.settings.people.map(person => person.id)
                serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id))
            }
            else{
                serverPrimary = serverPeople
            }

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
        },
    }
}
