<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";

import VaultItem from "@/components/common/questionnaires/fieldsets/vault/VaultItem";
import VaultItemShareOptions from "@/components/common/questionnaires/fieldsets/vault/VaultItemShareOptions";

// import {httpQuestionnaire} from "@/services";


export default {
  name: "VaultItemFull",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    VaultItem,
    VaultItemShareOptions,
  },
  data() {
    return {
      name: 'VaultItemFull',
      title: "Add Vault Item",
      url: `vault_item?id=${this.$route.params.vaultItemId}`,
      order:
          [
            'VaultItem',
            'VaultItemShareOptions',
          ],
      finishFunction: this.instructionsTaken,
      expiryAccessCodeOnFinish: true
    }
  },
  methods: {
    async instructionsTaken() {
      console.log('finished!')
      // return httpQuestionnaire
      //     .patch(this.url, {
      //       vault_id: this.form.id
      //     })
      //     .then((response) => {
      //       console.log(response.data);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    }
  },


};
</script>
