import { clone } from "@/mixins/clone";
let deepEqual = require('lodash/isEqual');

import { textHelpers } from "@/mixins/textHelpers";
import { scrollTo } from "@/mixins/scrollTo";

export const vaultPeopleHelpers = {
    mixins: [clone, scrollTo, textHelpers],
    methods: {
        deselectConfirm(value, message) {
            this.$bvModal.msgBoxConfirm(message,
                {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    cancelVariant: 'outline-primary',
                    okVariant: 'secondary',
                    okTitle: 'Confirm',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).
            then((confirmed) => {
                if (confirmed) {
                   this.deselect(value)
                }
            }).
            catch(() => {
                // An error occurred
            });
        },
        deselect(value) {
            // deselect only
            let index = -1
            if (value.type === 'person') {
                index = this.data.findIndex(person => person.id === value.id)
            } 
            if (index >= 0) {
                this.data.splice(index, 1)
                this.save()
            }
        },
        savePersonForm (event, person) {
            let index = -1
            // if person is selected: find index
            if (event.type === 'person') {
                index = this.data.findIndex(object => object.id === person.id)
            }

            // then save form data
            if (index >= 0) {
                this.setFormData(event, person, index)
            }
            // other-wise saving is done through database client person using inputs
        },
        setFormData(event, person, index) {
            // save form data of selected person
            // update person
            this.data[index] = event

            // person.type === 'person'
            // save server person used to keep in sync with db ClientPerson
            let data = this.clone(person)
            let serverPersonFound = this.$store.getters.people.find(option => option.id === person.id)
            if (serverPersonFound) {
                data.relationship_to_client = serverPersonFound.relationship_to_client
                if (!deepEqual(Object.assign({}, serverPersonFound), Object.assign({}, data)))  this.patch(data)
            }
            this.$store.commit('personUpdate', data)

            if (this.noSave) return
            this.$nextTick(() => {
                this.save()
            })
        },
        cardTitle (person) {
            return `${this.fullName(person)} (${ person.relationship})`
        }
    }
}
